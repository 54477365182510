import request from '@/utils/request'

/**
  * 查询某个资源类型的配置应用列表
  */
export function resConfListApi(data = {}) {
  return request({
    url: 'resConf/resConfList',
    method: 'POST',
    data
  })
}

/**
  * 管理某个资源类型的配置生效的应用、渠道、版本，编辑或添加或删除
  */
export function resProductConfManageApi(data = {}) {
  return request({
    url: 'resConf/resProductConfManage',
    method: 'POST',
    data
  })
}


/**
  * 查询某个资源类型的配置内容
  */
export function resItemsListApi(data = {}) {
  return request({
    url: 'resConf/resItemsList',
    method: 'POST',
    data
  })
}

/**
  * 配置资源的部份可选参数
  */
export function resParamsApi(data = {}) {
  return request({
    url: 'resConf/resParams',
    method: 'POST',
    data
  })
}

/**
  * 资源配置
  */
export function resItemsManageApi(data = {}) {
  return request({
    url: 'resConf/resItemsManage',
    method: 'POST',
    data
  })
}

/**
  * 配置应用ctr
  */
export function appCtrConfigApi(data = {}) {
  return request({
    url: 'resConf/appCtrConfig',
    method: 'POST',
    data
  })
}