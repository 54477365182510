<template>
  <div>
    <NavTitle class="mb16" :title="title">
      <template #right>
        <div class="title-row">
          <template v-if="tabList && tabList.length > 0">
            <div class="btn-operate" @click="handleDelete()">
              <img src="@/assets/images/icon-delete.png">
              <span class="red-text">删除选中分栏</span>
            </div>
            <div class="btn-operate" @click="toEdit()">
              <img src="@/assets/images/icon-edit.png">
              <span>编辑选中分栏</span>
            </div>
            <div class="btn-operate" @click="movePre()">
              <img style="transform: rotate(180deg);" src="@/assets/images/next.png">
              <span>前移选中分栏</span>
            </div>
            <div class="btn-operate" @click="moveNext()">
              <img src="@/assets/images/next.png">
              <span>后移选中分栏</span>
            </div>
          </template>
          <div class="btn-operate" @click="toAdd">
            <img src="@/assets/images/add-fill.png">
            <span class="theme-text">添加分栏</span>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="container">
      <!-- <el-menu :default-active="tabIndex" mode="horizontal" active-text-color="#08C8BD"
      @select="tabClick">
        <el-menu-item :index="index" v-for="(item, index) in tabList" :key="index">{{item.title}}</el-menu-item>
      </el-menu> -->
      <div class="tab-list" v-if="tabList.length > 0">
        <div :class="['tab', tabIndex == index ? 'active' : '']" v-for="(item, index) in tabList" :key="index" @click="tabClick(index)">{{item.title}}</div>
      </div>
      <el-alert title="下面展示的职位为配置好分栏后的预览展示，不可进行交互，只可编辑分栏配置" type="warning" show-icon :closable="false"></el-alert>

      <el-row :gutter="20" class="mt16" v-if="postList && postList.length > 0">
        <el-col :span="12" class="pointer mb16" v-for="(item, index) in postList" :key="index">
          <div class="post">
            <div class="flex between-center">
              <div class="pname">{{item.postTitle}}</div>
              <div class="price">{{item.priceDesc}}</div>
            </div>
            <div class="tag-list mt8">
              <div class="tag" v-for="(tag, index) in item.tags" :key="index">{{tag}}</div>
            </div>
            <div class="flex between-center mt16">
              <div class="flex items-center">
                <img class="mer-logo" src="@/assets/images/company.png"/>
                <span class="mer-name">{{item.merchantName}}</span>
              </div>
              <div class="btn-enroll">立即报名</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import { resItemsListApi, resItemsManageApi } from '@/api/resConf.js'
import { postListApi } from '@/api/post.js'
export default {
  name: 'ColumnList',
  components: {
    NavTitle
  },
  data() {
    return {
      resType: this.$route.query.resType,
      confId: this.$route.query.confId,
      title: this.$route.query.title || '',
      tabIndex: 0,
      tabList: [],
      postList: []
    }
  },
  created() {
    this.getTabList()
  },
  methods: {
    getTabList() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType
      }
      console.log('formData', formData)
      resItemsListApi(formData).then(res => {
        console.log('分栏列表', res)
        this.tabList = res.data.tabs || []
        if(this.tabList.length > 0) {
          this.getPostList()
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getPostList() {
      postListApi({
        type: this.tabList[this.tabIndex].type,
        startPage: 0,
      }).then(res => {
        console.log('职位列表', res)
        this.postList = res.data.posts || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    tabClick(index) {
      this.tabIndex = index
      this.getPostList()
    },
    toAdd() {
      this.$router.push({
        path: '/resource/columnEdit',
        query: {
          resType: this.resType,
          confId: this.confId
        }
      })
    },
    toEdit() {
      this.$router.push({
        path: '/resource/columnEdit',
        query: {
          resType: this.resType,
          confId: this.confId,
          isEdit: true,
          editIndex: this.tabIndex
        }
      })
    },
    handleDelete() {
      this.$confirm('确认删除该分栏吗？').then(action => {
        if(action == 'confirm') {
          this.tabList.splice(this.tabIndex, 1)
          this.saveConfig()
          if(this.tabList.length == 0) {
            this.postList == []
          } else {
            this.tabIndex = this.tabIndex - 1 < 0 ? 0 : this.tabIndex - 1
            this.getPostList()
          }
        }
      })
    },
    movePre() {
      const index = this.tabIndex
      if(index <= 0) return
      const preItem = this.tabList[index - 1]
      this.$set(this.tabList, index - 1, this.tabList[index])
      this.$set(this.tabList, index, preItem)
      this.tabIndex--
      this.saveConfig()
    },
    moveNext() {
      const index = this.tabIndex
      if(index == this.tabList.length - 1) return
      const nextItem = this.tabList[index + 1]
      this.$set(this.tabList, index + 1, this.tabList[index])
      this.$set(this.tabList, index, nextItem) 
      this.tabIndex++
      this.saveConfig()
    },
    saveConfig() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType,
        tabs: this.tabList
      }
      console.log('formData', formData)
      resItemsManageApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.getTabList()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title-row {
  display: flex;
  align-items: center;
  .btn-operate {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
.container {
  padding: 0 24px;
}
.tab-list {
  display: flex;
  align-items: center;
  height: 54px;
  background-color: #fff;
  padding-left: 24px;
  .tab {
    font-size: 14px;
    font-weight: bold;
    margin-right: 24px;
    cursor: pointer;
  }
  .active {
    position: relative;
    color: var(--theme-color);
    &::after {
      content:'';
      position: absolute;
      height: 2px;
      width: 30px;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--theme-color);
    }
  }
}
.post {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 24px;
  .pname {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .price {
    font-size: 16px;
    font-weight: bold;
    color: var(--theme-color-red);
    margin-left: 10px;
  }
  .tag-list {
    display: flex;
    align-items: center;
    font-size: 14px;
    .tag {
      height: 26px;
      line-height: 26px;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #F3F7F8;
      margin-right: 8px;
    }
  }
  .mer-logo {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-right: 4px;
  }
  .mer-name {
    color: #99A3A3;
  }
  .btn-enroll {
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    border: 1px solid #99A3A3;
    color: #99A3A3;
    padding: 0 12px;
  }
}
</style>